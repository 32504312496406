import logo from './logo.svg';
import './App.css';

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import video from './../src/images/landing.mp4'
import logo1 from './../src/images/svgLayer_2.png'
import ContactForm from "../src/ContactUs"
import ReactPlayer from "react-player";
import img1 from "./images/fb.png"
import img2 from "./images/insta.png";

function App() {


  const width = window.innerWidth ? window.innerWidth :0
  return (
    <>
      <div>
        <section className="showcase">
          <div className="video-container">
            {/* <div
              style={{
                width: "100px",
                position: "fixed",
                zIndex: "9999999",
                display: "flex",
                justifyContent: "space-between",
                top: "20px",
                left: "20px",
              }}
            >
              <a
                href="https://www.facebook.com/staceystaffingservices/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={img1} height="40" width="40" alt="" />
              </a>
              <a
                href="https://instagram.com/ks_staffing_solutions?igshid=YmMyMTA2M2Y="
  target="_blank"
                rel="noreferrer"

              >
                <img src={img2} height="50" width="50" alt="" />
              </a>
            </div> */}
            <video loop={true} autoPlay="autoplay" id="vid" muted>
              <source type="video/mp4" src={video} />
            </video>
            {/* <ReactPlayer playing={true} url={video} /> */}
            <footer className="footer">
              &copy; 2023 Stacey's Staffing Services. All Rights Reserved.
              Design By Vcana Global, Inc.
            </footer>
          </div>

          <div className="content">
            <div className="main">
              <div className="logo">
                <img src={logo1} alt="logo" />
              </div>
              <h1 className="contenth1" style={{ fontStyle: "italic" }}>
                Our Web Platform is Coming Soon...
              </h1>
              <p>
                We're working hard to finish the development of this platform.
                Fill the adjoining form to receive updates and to be notified
                when we launch!
              </p>
            </div>
            <div className="contact-us">
              <ContactForm />
            </div>
          </div>
        </section>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
      />
    </>
  );
}

export default App;
