import React,{useRef} from "react";

import { useForm } from 'react-hook-form';

import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import img1 from "./images/fb.png";
import img2 from "./images/insta.png";


//helper
const errorMessage = (error) => {
    return (
      <div style={{ color: "red", margintTop: "0px !important" , display:"flex"}}>{error}</div>
    );
  };
  
  const required = "This field is required";
  const minLength = "Name must be 4 letters";

const ContactForm = () => {

    const form = useRef()


    const { register, handleSubmit,   reset,errors, getValues, setValue } = useForm();
 


  const SubmitHandler = async(event) => {
    // event.preventDefault();
    console.log(getValues());
    console.log(errors)

    const  data = {
      fname : getValues().firstName,
      lname: getValues().lastName,
      mobile: getValues().mobile,
      email: getValues().email,
      msg: getValues().message,
    }

try{



  const res = await fetch('https://ksapi.vcanaglobal.ga/api/v1/kscs?' + new URLSearchParams({
    ...data
 }),
 {
  method: 'Post',
  'Content-Type': 'application/json', 

 }
 )

 console.log('jjjjhhjjbj',res)
 
 if(res.status===200) {
  emailjs
    .sendForm(
      "service_ja136hq",
      "template_ppcdic9",

      form.current,
      "NO1lUo7h_bt56qSmV"
    )
    .then(
      (result) => {
        // alert('message sent successfully...');
        // toast.success('message sent successfully !')

        if (result.text === "OK") {
          toast.success("Message Sent Successfully! ");
          reset({
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            message: "",
          });
        }

        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
        toast.error(error.text);
      }
    );
 }


} catch(error){
  console.log(error);

  // toast.error(error)
}

 




    // e.preventDefault();
    
  };
  

  return (
    <>
      <h1 style={{ color: "#32363d" }}>
        Stacey's Staffing Services is Launching in Fall 2023!
      </h1>
      <p style={{ color: "#32363d" }}>
        Please fill the below form to receive updates and to be notified when we
        launch.
      </p>
      <form ref={form} onSubmit={handleSubmit(SubmitHandler)}>
        <div>
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            ref={register({ required: true, minLength: 4 })}
          />

          {errors.firstName &&
            errors.firstName.type === "required" &&
            errorMessage(required)}
          {errors.firstName &&
            errors.firstName.type === "minLength" &&
            errorMessage(minLength)}
        </div>
        <div>
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            ref={register({ required: true, minLength: 4 })}
          />
          {errors.lastName &&
            errors.lastName.type === "required" &&
            errorMessage(required)}
          {errors.lastName &&
            errors.lastName.type === "minLength" &&
            errorMessage(minLength)}
        </div>
        <div>
          <label htmlFor="mobile">Mobile</label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            placeholder="Mobile no."
            ref={register({
              required: true,
              pattern: /^\d{10}$/,
            })}
          />
          {errors.mobile &&
            errors.mobile.type === "required" &&
            errorMessage(required)}
          {errors.mobile &&
            errors.mobile.type === "pattern" &&
            errorMessage("Enter a valid mobile")}
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            ref={register({
              required: true,
              pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            })}
          />
          {errors.email &&
            errors.email.type === "required" &&
            errorMessage(required)}
          {errors.email &&
            errors.email.type === "pattern" &&
            errorMessage("enter a valid email")}
        </div>
        <div>
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Enter Your Message"
            ref={register({
              required: true,
            })}
          />
          {errors.message &&
            errors.message.type === "required" &&
            errorMessage(required)}
        </div>
        <button className="submit_btn" type="submit">
          Submit
        </button>
      </form>
      <div
        style={{
          width: "57%",

          display: "flex",
          justifyContent: "space-between",

          textColor: "black",
        }}
      >
        <div style={{ marginLeft: "26px" }}>
          <p style={{ width: "4opx ", fontSize: "20px", paddingRight: "10px" }}>
            Social Link
          </p>
        </div>
        <a
          href="https://www.facebook.com/staceystaffingservices/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={img1} height="28" width="28" alt="" />
        </a>
        <a
          href="https://instagram.com/ks_staffing_solutions?igshid=YmMyMTA2M2Y="
          target="_blank"
          rel="noreferrer"
        >
          <img src={img2} height="30" width="30" alt="" />
        </a>
      </div>
    </>
  );
};

export default ContactForm;
